<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import helperFile from "../../../helpers/helper";

export default {
  page: {
    title: "Corporate Branch Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],

      title: "Corporate Branches",
      items: [
        {
          text: "View",
        },
        {
          text: "Corporate Branch",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",

          sortable: false,
        },
        {
          key: "corporateName",
          sortable: true,
        },
        {
          key: "corporateCode",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "branchName",
          sortable: true,
        },
        {
          key: "address",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readCorporateBranches();
    this.substring = helperFile.substringFunction;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "CorporateBranch",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "corporate-branches.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    readCorporateBranches() {
      this.axios
        .get(this.$loggedRole+"/viewAllCorporateBranches", {
          params: {
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.$storageData.profile.city,
          },
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //console.log((response));
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                    type="button"
                    class="btn btn-themeBrown"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      style="margin-left: 5px; margin-right: 5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 table-striped">
              <b-table
                striped
                hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(corporateName)="data">
                  <router-link
                    :to="{
                      name: 'corporateProfile',
                      params: { id: data.item.corpBranchID },
                    }"
                  >
                    {{ data.item.corporateName }}</router-link
                  >
                </template>

                <template v-slot:cell(Action)="data">
                  <!-- <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i> -->
                  <router-link
                    :to="{
                      name: 'usersTable',
                      params: { id: data.item.corpBranchID },
                    }"
                  >
                    <i class="fas fa-users" title="View Users"></i
                  ></router-link>
                </template>

                <template v-slot:cell(address)="data">
                  <span :title="data.item.address">{{
                    substring(data.item.address)
                  }}</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
